<template>
  <div class="form-partnership-detail">
    <bg-text size="heading-2" class="mb-32">{{ xPropertyDetail.name }}</bg-text>
    <bg-text size="heading-4" class="mb-32">Detail Kerja Sama</bg-text>

    <bg-alert
      v-if="isEdit"
      class="mb-32"
      title="Perubahan pada total kamar properti hanya dapat dilakukan di halaman admin Mamikos (Bangkerupux)."
      :closeable="false"
    />

    <template v-if="loading.disbursementPartnership">
      <bg-grid>
        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8">Jenis Produk</bg-text>
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8">Model Kerja Sama</bg-text>
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8">Basic Comission</bg-text>
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8">Total Kamar</bg-text>
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>
      </bg-grid>

      <bg-grid>
        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8">Jangka Waktu Kerja Sama</bg-text>
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8">Awal Kerja Sama</bg-text>
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8">Akhir Kerja Sama</bg-text>
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8">Biaya Keanggotaan</bg-text>
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>
      </bg-grid>

      <bg-divider class="mb-32" />

      <bg-grid>
        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8">Tipe Add On JP</bg-text>
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8">Persentase Add On JP</bg-text>
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8">Jumlah Add On JP</bg-text>
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>
      </bg-grid>

      <bg-divider class="mb-32" />

      <bg-grid>
        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8">Tipe Add On ADP</bg-text>
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8">Persentase Add On ADP</bg-text>
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8">Jumlah Add On ADP</bg-text>
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>
      </bg-grid>

      <bg-divider class="mb-32" />

      <bg-grid>
        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8"
            >Persentase Pendapatan Pemilik</bg-text
          >
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-text size="title-5" class="mb-8"
            >Persentase Pendapatan Mamikos</bg-text
          >
          <bg-skeleton width="100%" height="48px" />
        </bg-grid-item>
      </bg-grid>
    </template>

    <template v-else>
      <bg-grid>
        <bg-grid-item :col="4" class="mb-32">
          <bg-field label="Jenis Produk" class="mb-0">
            <bg-select
              :disabled="isRenew"
              v-model="values.product_type"
              :options="options.productType"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-field label="Model Kerja Sama" class="mb-0">
            <bg-select
              v-model="values.revenue_model"
              :options="options.revenueModel"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-field
            label="Basic Comission"
            class="mb-0"
            :error="valuesToBeSent.basic_commision > 1"
            :message="
              valuesToBeSent.basic_commision > 1
                ? 'Tidak boleh melebihi 100%'
                : ''
            "
          >
            <bg-input
              v-model="values.basic_commision"
              suffix="%"
              @input="values.basic_commision = onlyNumber($event)"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-field label="Total Kamar" class="mb-0">
            <bg-input disabled v-model="values.room_total" />
          </bg-field>
        </bg-grid-item>
      </bg-grid>

      <bg-grid>
        <bg-grid-item :col="4" class="mb-32">
          <bg-field
            label="Jangka Waktu Kerja Sama"
            class="mb-0"
            :error="valuesToBeSent.cooperation_period > 48 ||
              valuesToBeSent.cooperation_period < 1
              "
            :message="valuesToBeSent.cooperation_period > 48 ||
                valuesToBeSent.cooperation_period < 1
                ? 'Harus antara 1 bulan sampai 48 bulan'
                : ''
              "
          >
            <bg-input
              :disabled="isEdit && !isSuperAdmin"
              v-model="values.jangka_waktu_kerja_sama"
              suffix="Bulan"
              @input="
                handleChangePeriod();
              values.jangka_waktu_kerja_sama = onlyNumber($event);
              "
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-field label="Awal Kerja Sama" class="mb-0">
            <bg-datepicker
              :disabled="!isEdit || (isEdit && !isSuperAdmin)"
              v-model="values.start_contract"
              format="dd MMMM yyyy"
              fixed-position="top-left"
              @selected="handleChangePeriod"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-field label="Akhir Kerja Sama" class="mb-0" disabled>
            <bg-input disabled v-model="values.end_contract" />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <input-currency-masking
            field-label="Biaya Keanggotaan"
            :field-error="valuesToBeSent.biaya_keanggotaan > 150000000"
            :field-message="valuesToBeSent.biaya_keanggotaan > 150000000
              ? 'Tidak boleh melebihi Rp 150.000.000'
              : ''
            "
            prefix="Rp"
            v-model="values.biaya_keanggotaan"
          />
        </bg-grid-item>
      </bg-grid>

      <bg-divider class="mb-32" />

      <bg-grid>
        <bg-grid-item :col="4" class="mb-32">
          <bg-field label="Tipe Add On JP" class="mb-0">
            <bg-select
              v-model="values.type_add_on_jp"
              :options="options.addontypeJP"
              @input="changePercentageAndAmountToZero($event, 'jp')"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-field
            label="Persentase Add On JP"
            class="mb-0"
            :error="valuesToBeSent.percentage_add_on_jp > 1"
            :message="
              valuesToBeSent.percentage_add_on_jp > 1
                ? 'Tidak boleh melebihi 100%'
                : ''
            "
          >
            <bg-input
              :disabled="isRenew || values.type_add_on_jp === '-'"
              v-model="values.percentage_add_on_jp"
              suffix="%"
              @input="values.percentage_add_on_jp = onlyNumber($event)"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <input-currency-masking
            field-label="Jumlah Add On JP"
            :field-error="valuesToBeSent.amount_add_on_jp > 150000000"
            :field-message="
              valuesToBeSent.amount_add_on_jp > 150000000
                ? 'Tidak boleh melebihi Rp 150.000.000'
                : ''
            "
            prefix="Rp"
            :disabled="isRenew || values.type_add_on_jp === '-'"
            ref="amount_add_on_jp"
            v-model="values.amount_add_on_jp"
          />
        </bg-grid-item>
      </bg-grid>

      <bg-divider class="mb-32" />

      <bg-grid>
        <bg-grid-item :col="4" class="mb-32">
          <bg-field label="Tipe Add On ADP" class="mb-0">
            <bg-select
              v-model="values.type_add_on_adp"
              :options="options.addontypeADP"
              @input="changePercentageAndAmountToZero($event, 'adp')"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-field
            label="Persentase Add On ADP"
            class="mb-0"
            :error="valuesToBeSent.percentage_add_on_adp > 1"
            :message="
              valuesToBeSent.percentage_add_on_adp > 1
                ? 'Tidak boleh melebihi 100%'
                : ''
            "
          >
            <bg-input
              :disabled="isRenew || values.type_add_on_adp === '-'"
              v-model="values.percentage_add_on_adp"
              suffix="%"
              @input="values.percentage_add_on_adp = onlyNumber($event)"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <input-currency-masking
            field-label="Jumlah Add On ADP"
            :field-error="valuesToBeSent.amount_add_on_adp > 150000000"
            :field-message="
              valuesToBeSent.amount_add_on_adp > 150000000
                ? 'Tidak boleh melebihi Rp 150.000.000'
                : ''
            "
            prefix="Rp"
            :disabled="isRenew || values.type_add_on_adp === '-'"
            ref="amount_add_on_adp"
            v-model="values.amount_add_on_adp"
          />
        </bg-grid-item>
      </bg-grid>

      <bg-divider class="mb-32" />

      <bg-grid>
        <bg-grid-item>
          <div class="toggle-text">
            <bg-switch v-model="toggleHybrid" @input="handleToggle" />
            <bg-text size="body-2">Model kerja sama hybrid</bg-text>
          </div>
        </bg-grid-item>
      </bg-grid>

      <bg-grid>
        <bg-grid-item :col="12" class="mb-16">
          <bg-text size="title-4"
            >Ketentuan Komisi dari Penyewa Booking</bg-text
          >
        </bg-grid-item>
        <bg-grid-item :col="4" class="mb-32">
          <bg-field label="Persentase Pendapatan Pemilik" class="mb-0">
            <bg-input disabled :value="percentageOwner" suffix="%" />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-field
            label="Persentase Pendapatan Mamikos"
            class="mb-0"
            :error="percentageMamikosExceed"
            :message="
              percentageMamikosExceed ? 'Tidak boleh melebihi 100%' : ''
            "
          >
            <bg-input
              :disabled="!isRenew"
              :value="percentageMamikos"
              suffix="%"
            />
          </bg-field>
        </bg-grid-item>
      </bg-grid>

      <bg-grid v-if="toggleHybrid">
        <bg-grid-item :col="12" class="mb-16">
          <bg-text size="title-4">Ketentuan Komisi dari Penyewa DBET</bg-text>
        </bg-grid-item>
        <bg-grid-item :col="4" class="mb-32">
          <bg-field label="Persentase Pendapatan Pemilik" class="mb-0">
            <bg-input disabled :value="percentageDbetOwner" suffix="%" />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item :col="4" class="mb-32">
          <bg-field
            label="Persentase Pendapatan Mamikos"
            class="mb-0"
            :error="percentageDbetMamikosExceed"
            :message="
              percentageDbetMamikosExceed ? 'Tidak boleh melebihi 100%' : ''
            "
          >
            <bg-input
              v-model="values.existing_tenant_comission_mamikos"
              @input="
                values.existing_tenant_comission_mamikos = Number(
                  onlyNumber($event)
                )
              "
              suffix="%"
            />
          </bg-field>
        </bg-grid-item>
      </bg-grid>

      <div v-if="isEdit && classUpgrade.is_class_upgrade">
        <bg-divider class="mb-32" />

        <bg-grid>
          <bg-grid-item :col="4" class="mb-32">
            <bg-field label="Tenor">
              <bg-input
                v-model.trim="classUpgrade.tenor"
                suffix="Bulan"
                data-testid="classUpgrade-tenor"
                @input="classUpgrade.tenor = onlyNumber($event)"
              />
            </bg-field>
          </bg-grid-item>

          <bg-grid-item :col="4" class="mb-32">
            <bg-field label="Tanggal Awal Repayment">
              <bg-datepicker
                v-model="classUpgrade.firstRepaymentDateRealValue"
                format="dd MMMM yyyy"
                placeholder="Isi tanggal awal repayment"
              />
            </bg-field>
          </bg-grid-item>

          <bg-grid-item :col="4" class="mb-32">
            <bg-field label="Tanggal Akhir Repayment">
              <bg-input
                :value="classUpgradeFinalRepaymentDate.displayValue"
                disabled
                placeholder="Isi tanggal akhir repayment"
                icon-right="calendar"
              />
            </bg-field>
          </bg-grid-item>

          <bg-grid-item :col="4" class="mb-32">
            <search-checkbox
              label="Kamar Naik Kelas"
              name="classUpgradeRoomlist"
              :placeholder="isLoadingClassUpgradeRoomList
                ? 'Memuat Daftar Kamar...'
                : 'Pilih Kamar Naik Kelas'
              "
              :list="classUpgradeRoomList"
              :checked="classUpgrade.marked_rooms"
              :is-loading-list="isLoadingClassUpgradeRoomList"
              :max-checked="0"
              :max-counter-display="0"
              has-select-all-option
              select-all-option-label="Semua Kamar"
              disable-search
              disable-clear
              @emit-checked-array="classUpgrade.marked_rooms = $event"
            />
          </bg-grid-item>
        </bg-grid>
      </div>

      <bg-grid class="button-container">
        <bg-grid-item :col="12">
          <bg-button
            variant="primary"
            @click="saveModal = true"
            :disabled="isNotValid"
            >Simpan</bg-button
          >
        </bg-grid-item>
      </bg-grid>
    </template>

    <bg-modal
      class="warn-modal"
      v-model="warnModal"
      desktop-size="sm"
      title="Perpanjang Kontrak Properti"
      description="Mohon pastikan jangka waktu kerja sama sesuai dengan yang telah disepakati dengan pemilik properti."
      button-main-text="Mengerti"
      button-main-variant="primary"
      :close-on-click-backdrop="false"
      @click-main-action="warnModal = false"
    >
    </bg-modal>

    <bg-modal v-model="saveModal" desktop-size="sm">
      <template>
        <bg-text class="mb-8" size="heading-4">{{ modalTitle }}</bg-text>
        <bg-text size="body-2"><span v-html="modalDescription" /></bg-text>
      </template>
      <template v-slot:footer>
        <div class="button-modal">
          <bg-button
            class="mr-16"
            @click="saveModal = false"
            :disabled="loading.save"
            >Batal</bg-button
          >
          <bg-button
            @click="handleSaveChanges"
            variant="primary"
            :loading="loading.save"
            >Simpan</bg-button
          >
        </div>
      </template>
    </bg-modal>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import dayjs from 'dayjs';

import forms from '@admin_endpoints/forms';
import propertyApi from '@admin_endpoints/property-detail';
import propertyClassUpgradeAPI from '@admin_endpoints/property-class-upgrade';

import {
  BgGrid,
  BgGridItem,
  BgInput,
  BgField,
  BgText,
  BgSelect,
  BgButton,
  BgModal,
  BgAlert,
  BgDivider,
  BgDatepicker,
  BgSkeleton,
  BgSwitch,
} from 'bangul-vue';

import InputCurrencyMasking from '@admin_molecules/InputCurrencyMasking';
import SearchCheckbox from '@admin_molecules/SearchCheckbox';

import {
  CLASS_UPGRADE_INITIAL_STATE,
  composeClassUpgradeData
} from '@admin/pages/PropertyDetailContract/utils/property-class-upgrade';

import { currencyFormatter } from 'Utils/formatter';

dayjs.locale('id');
const customParseFormat = require('dayjs/plugin/customParseFormat');

dayjs.extend(customParseFormat);

export default {
  name: 'FormPartnershipDetail',

  components: {
    BgGrid,
    BgGridItem,
    BgInput,
    BgField,
    BgText,
    BgSelect,
    BgButton,
    BgModal,
    BgAlert,
    BgDivider,
    BgDatepicker,
    BgSkeleton,
    BgSwitch,
    InputCurrencyMasking,
    SearchCheckbox
  },

  computed: {
    ...mapState('propertyDetail', ['xPropertyDetail']),
    propertyId() {
      return this.$route.params.propertyId;
    },
    isEdit() {
      return this.$route.name === 'edit.partnership-detail';
    },
    isRenew() {
      return this.$route.name === 'edit.renew-contract';
    },
    modalTitle() {
      let string = '';

      if (this.isEdit) {
        string = 'Yakin menyimpan perubahan?';
      }
      if (this.isRenew) {
        string = 'Yakin perpanjang kontrak properti?';
      }

      return string;
    },
    modalDescription() {
      let string = '';

      if (this.isEdit) {
        string = 'Mohon persiapkan dokumen pendukung yang dibutuhkan.';
      }
      if (this.isRenew) {
        string = `Jika terdapat perubahan data pada kontrak, silakan <b>Ubah</b> setelah kontrak diperpanjang.`;
      }

      return string;
    },
    valuesToBeSent() {
      const payload = {
        product_type: this.findKeyValueOnBangulSelectOptions(
          this.options.productType,
          this.values.product_type
        ),
        revenue_model: this.findKeyValueOnBangulSelectOptions(
          this.options.revenueModel,
          this.values.revenue_model
        ),
        basic_commision: Number(this.values.basic_commision) / 100,
        type_add_on_jp: this.findKeyValueOnBangulSelectOptions(
          this.options.addontypeJP,
          this.values.type_add_on_jp
        ),
        percentage_add_on_jp: Number(this.values.percentage_add_on_jp) / 100,
        amount_add_on_jp: Number(
          this.values.amount_add_on_jp.toString().replace(/\./g, '')
        ),
        type_add_on_adp: this.findKeyValueOnBangulSelectOptions(
          this.options.addontypeADP,
          this.values.type_add_on_adp
        ),
        percentage_add_on_adp: Number(this.values.percentage_add_on_adp) / 100,
        amount_add_on_adp: Number(
          this.values.amount_add_on_adp.toString().replace(/\./g, '')
        ),
        biaya_keanggotaan: Number(
          this.values.biaya_keanggotaan.toString().replace(/\./g, '')
        ),
        existing_tenant_comission_mamikos: this.toggleHybrid
          ? Number(this.values.existing_tenant_comission_mamikos) / 100
          : null,
        started_at: dayjs(this.values.start_contract).format('YYYY-MM-DD'),
        ended_at: dayjs(this.values.end_contract, 'DD MMMM YYYY', 'id').format(
          'YYYY-MM-DD'
        ),
        contract_id: this.isRenew ? this.partnership.id : undefined,
      };

      if (this.classUpgrade.is_class_upgrade) {
        const { firstRepaymentDateRealValue, marked_rooms } = this.classUpgrade;
        const { realValue: endDate } = this.classUpgradeFinalRepaymentDate;

        payload.class_upgrade = {
          first_repayment_date: firstRepaymentDateRealValue
            ? dayjs(firstRepaymentDateRealValue).format('YYYY-MM-DD')
            : null,
          final_repayment_date: endDate,
          marked_rooms
        };
      }

      return payload;
    },
    isNotValid() {
      return (
        this.valuesToBeSent.basic_commision > 1 ||
        this.valuesToBeSent.percentage_add_on_jp > 1 ||
        this.valuesToBeSent.amount_add_on_jp > 150000000 ||
        this.valuesToBeSent.percentage_add_on_adp > 1 ||
        this.valuesToBeSent.amount_add_on_adp > 150000000 ||
        this.valuesToBeSent.biaya_keanggotaan > 150000000 ||
        this.valuesToBeSent.cooperation_period > 48 ||
        this.valuesToBeSent.cooperation_period < 1 ||
        this.percentageDbetMamikosExceed
      );
    },

    percentageOwner() {
      return 100 - this.percentageMamikos;
    },
    percentageMamikos() {
      return (
        Number(this.values.basic_commision) +
        Number(this.values.percentage_add_on_jp) +
        Number(this.values.percentage_add_on_adp)
      );
    },
    percentageMamikosExceed() {
      return this.percentageMamikos > 100;
    },

    percentageDbetOwner() {
      return 100 - Number(this.values.existing_tenant_comission_mamikos);
    },
    percentageDbetMamikosExceed() {
      return Number(this.values.existing_tenant_comission_mamikos) > 100;
    },
    isSuperAdmin() {
      return this.$store.getters.isSuperAdmin;
    },

    classUpgradeFinalRepaymentDate() {
      const { tenor, firstRepaymentDateRealValue } = this.classUpgrade;

      if (!tenor || !firstRepaymentDateRealValue) {
        return { displayValue: '', realValue: '' };
      }

      const endDate = dayjs(firstRepaymentDateRealValue).add(
        tenor,
        'month'
      );

      return {
        displayValue: endDate.format('DD MMMM YYYY'),
        realValue: endDate.format('YYYY-MM-DD'),
      };
    }
  },

  data() {
    return {
      loading: {
        disbursementPartnership: true,
        save: false,
      },
      warnModal: false,
      saveModal: false,
      partnership: {},
      values: {},
      partnershipPeriod: '',
      options: {
        productType: [],
        revenueModel: [],
        addontypeJP: [],
        addontypeADP: [],
      },
      startContractDate: '',
      initialContractEndDate: '',
      timer: null,

      toggleHybrid: false,

      isLoadingClassUpgradeRoomList: false,
      classUpgradeRoomList: [],
      classUpgrade: CLASS_UPGRADE_INITIAL_STATE,
    };
  },

  created() {
    this.handleUpdateBreadcrumb();
    this.warnModal = this.isRenew;
    this.consumeDisbursementAndPartnership();
  },

  watch: {
    'classUpgrade.is_class_upgrade': {
      handler(value) {
        if (value) {
          this.fetchClassUpgradeRoomList();
        }
      }
    }
  },

  methods: {
    ...mapMutations('breadcrumb', ['updateBreadcrumb']),

    handleUpdateBreadcrumb() {
      this.updateBreadcrumb({
        index: 1,
        item: {
          name: this.xPropertyDetail.name,
          url: `/property-detail/${this.propertyId}/contract`,
        },
      });
    },

    async fetchDisbursementAndPartnership() {
      let result = {};

      try {
        result = await propertyApi.getDisbursementAndPartnership(
          this.propertyId
        );
      } catch (error) {
        result.hasError = true;
        console.error(error);
      }
      return result;
    },
    async fetchListProductType() {
      let result = {};
      try {
        result = await forms.getListProductType();
      } catch (error) {
        result.hasError = true;
        console.error(error);
      }
      return result;
    },
    async fetchListRevenueModel() {
      let result = {};
      try {
        result = await forms.getListRevenueModel();
      } catch (error) {
        result.hasError = true;
        console.error(error);
      }
      return result;
    },
    async fetchListAddontype() {
      let result = {};
      try {
        result = await forms.getListAddontype();
      } catch (error) {
        result.hasError = true;
        console.error(error);
      }
      return result;
    },
    async updateEditPartnershipDetail(params) {
      let result = {};
      try {
        result = await forms.putEditPartnershipDetail(this.propertyId, params);
      } catch (error) {
        result.hasError = true;
        this.$toast.show(error);
        console.error(error);
      }
      return result;
    },
    async sendEditRenewContract(params) {
      let result = {};
      try {
        result = await forms.postEditRenewContract(this.propertyId, params);
      } catch (error) {
        result.hasError = true;
        this.$toast.show(error);
        console.error(error);
      }
      return result;
    },

    async consumeDisbursementAndPartnership() {
      this.loading.disbursementPartnership = true;

      const [
        response,
        productType,
        revenueModel,
        addontype,
      ] = await Promise.all([
        this.fetchDisbursementAndPartnership(),
        this.fetchListProductType(),
        this.fetchListRevenueModel(),
        this.fetchListAddontype(),
      ]);

      if (!response.hasError) {
        this.partnership = response.data.data.detail;

        this.values = { ...this.partnership };

        this.values.basic_commision = this.partnership.basic_commision
          .replace('%', '')
          .replace('-', 0);
        this.values.percentage_add_on_jp = this.partnership.percentage_add_on_jp
          .replace('%', '')
          .replace('-', 0);
        this.values.percentage_add_on_adp = this.partnership.percentage_add_on_adp
          .replace('%', '')
          .replace('-', 0);
        this.values.amount_add_on_jp =
          this.partnership.amount_add_on_jp === '-'
            ? 0
            : currencyFormatter(this.partnership.amount_add_on_jp);
        this.values.amount_add_on_adp =
          this.partnership.amount_add_on_adp === '-'
            ? 0
            : currencyFormatter(this.partnership.amount_add_on_adp);
        this.values.biaya_keanggotaan =
          this.partnership.biaya_keanggotaan === '-'
            ? 0
            : currencyFormatter(this.partnership.biaya_keanggotaan);
        this.values.percentage_pendapatan_owner = this.partnership.percentage_pendapatan_owner
          .replace('%', '')
          .replace('-', 0);
        this.values.percentage_pendapatan_mamikos = this.partnership.percentage_pendapatan_mamikos
          .replace('%', '')
          .replace('-', 0);
        this.values.end_contract = dayjs(this.partnership.end_contract)
          .locale('id')
          .format('DD MMMM YYYY');
        this.values.existing_tenant_comission_mamikos =
          this.partnership.existing_tenant_comission_mamikos === null
            ? '0'
            : this.partnership.existing_tenant_comission_mamikos
                .replace('%', '')
                .replace('-', 0);
        this.toggleHybrid =
          this.partnership.existing_tenant_comission_mamikos !== null;

        this.values.start_contract = dayjs(this.partnership.start_contract).$d;
        this.initialEndContractDate = dayjs(this.partnership.end_contract).$d;

        if (this.isRenew) {
          this.values.start_contract = dayjs(this.initialEndContractDate).add(
            1,
            'day'
          ).$d;
          this.handleChangePeriod();
        }

        if (this.isEdit) {
          const {
            class_upgrade: classUpgrade = CLASS_UPGRADE_INITIAL_STATE
          } = response.data.data;

          this.classUpgrade = composeClassUpgradeData({
            classUpgrade,
            roomTotal: this.partnership.room_total
          });
        }
      }

      if (!productType.hasError) {
        const temp = productType.data.product_types;
        this.options.productType = temp.map(item => ({
          val: item.name,
          label: item.name,
          key: item.key,
        }));
      }

      if (!revenueModel.hasError) {
        const temp = revenueModel.data.revenue_models;
        this.options.revenueModel = temp.map(item => ({
          val: item.name,
          label: item.name,
          key: item.key,
        }));
      }

      if (!addontype.hasError) {
        const temp = addontype.data.addon_types;

        this.options.addontypeJP = temp.reduce(
          (prev, curr) => {
            if (curr.group === 'JP') {
              prev.push({
                val: curr.name,
                label: curr.name,
                key: curr.key,
              });
            }
            return prev;
          },
          [
            {
              val: '-',
              label: 'None',
              key: '',
            },
          ]
        );

        this.options.addontypeADP = temp.reduce(
          (prev, curr) => {
            if (curr.group === 'ADP') {
              prev.push({
                val: curr.name,
                label: curr.name,
                key: curr.key,
              });
            }
            return prev;
          },
          [
            {
              val: '-',
              label: 'None',
              key: '',
            },
          ]
        );
      }

      this.loading.disbursementPartnership = false;
    },

    async saveEditPartnershipDetail() {
      this.loading.save = true;

      const params = { ...this.valuesToBeSent };

      params.type_add_on_jp =
        params.type_add_on_jp === '' ? null : params.type_add_on_jp;
      params.type_add_on_adp =
        params.type_add_on_adp === '' ? null : params.type_add_on_adp;
      params.amount_add_on_jp =
        params.amount_add_on_jp === '' ? 0 : params.amount_add_on_jp;
      params.amount_add_on_adp =
        params.amount_add_on_adp === '' ? 0 : params.amount_add_on_adp;
      params.biaya_keanggotaan =
        params.biaya_keanggotaan === '' ? 0 : params.biaya_keanggotaan;

      const response = await this.updateEditPartnershipDetail(params);

      if (!response.hasError) {
        this.$toast.show('Perubahan berhasil disimpan.');
        this.$router.push(`/property-detail/${this.propertyId}/contract`);
      }

      this.loading.save = false;
    },

    async saveEditRenewContract() {
      this.loading.save = true;

      const params = { ...this.valuesToBeSent };
      const response = await this.sendEditRenewContract(params);

      if (!response.hasError) {
        this.$toast.show('Kontrak properti berhasil diperpanjang.');
        this.$router.push(`/property-detail/${this.propertyId}/contract`);
      }

      this.loading.save = false;
    },

    async fetchClassUpgradeRoomList() {
      try {
        this.isLoadingClassUpgradeRoomList = true;

        const response = await propertyClassUpgradeAPI
          .getPropertyClassUpgradeRoomList(
            this.propertyId
          );

        if (response && response.data) {
          const { rooms } = response.data;

          this.classUpgradeRoomList = rooms.map(({ id, number, type }) => ({
            value: id,
            label: `${type} - ${number}`
          }));
        }
      } catch (error) {
        const errorMessage =
          error?.response?.data?.error || 'Terjadi galat, silakan coba lagi.';

        this.$toast.show(errorMessage);
        this.$error.report(error);
      } finally {
        this.isLoadingClassUpgradeRoomList = false;
      }
    },

    changePercentageAndAmountToZero(value, type) {
      if (value === '-' && type === 'jp') {
        this.values.percentage_add_on_jp = 0;
        this.values.amount_add_on_jp = 0;
      }

      if (value === '-' && type === 'adp') {
        this.values.percentage_add_on_adp = 0;
        this.values.amount_add_on_adp = 0;
      }
    },

    async handleChangePeriod() {
      await this.$nextTick();

      this.values.end_contract = dayjs(this.values.start_contract)
        .add(Number(this.values.jangka_waktu_kerja_sama), 'month')
        .subtract(1, 'day')
        .format('DD MMMM YYYY');
    },

    handleSaveChanges() {
      if (this.isEdit) {
        this.saveEditPartnershipDetail();
      }

      if (this.isRenew) {
        this.saveEditRenewContract();
      }
    },

    findKeyValueOnBangulSelectOptions(array, value) {
      const keyValue = array.find(item => item.val === value);
      if (keyValue) {
        return keyValue.key;
      }

      return null;
    },

    inputMoneyAmount(value) {
      return currencyFormatter(value);
    },

    onlyNumber(value) {
      return value.replace(/\D/g, '');
    },

    handleToggle() {
      if (!this.toggle) {
        this.values.existing_tenant_comission_mamikos = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./FormPartnershipDetail.scss"></style>
